
import { Flex, Box, Heading, Text, Button, Image } from '@chakra-ui/react'
import banner1 from '../multimedia/banners/b0.jpg'
import banner2 from '../multimedia/banners/b1.jpg'
import banner3 from '../multimedia/banners/b2.jpg'
import { Link } from 'react-router-dom'
import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function HeroBanner3() {

    const responsive = {
        largeDesktop: {
            breakpoint: { max: 3000, min: 1400 },
            items: 1,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 1,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 0
        }
    };

    return (

        <CarouselMulti
            showDots={false}
            rewind={true}
            rewindWithAnimation={true}
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}

        >

            <Flex flexDir={{ base: 'column', lg: 'row' }} bg={"easycar1.500"}>
                <Box order={{ base: 2, lg: 1 }} className={'sideLeft'} color={"white"} textAlign={"center"} w={"full"} maxW={{ base: "full", lg: "40%" }} p={"6px"} pl={"5%"} m={"auto"}>
                    <Text fontSize={{ base: "initial", lg: "2xl" }}>
                        El auto de tus sueños esta mas cerca de lo que te imaginas.
                    </Text>
                    <Heading>
                        ¡Hazlo tuyo hoy!
                    </Heading>
                    <Text fontSize={{ base: "initial", lg: "2xl" }}>
                        Llevatelo en cuotas
                    </Text>
                    <br /><br />
                    <Link to={"/cars"}>
                        <Button colorScheme={"easycar2"} >Precalifica ahora</Button>
                    </Link>
                    <br /><br />
                </Box>
                <Box order={{ base: 1, lg: 2 }} className={'sideRight'} w={"full"} maxW={{ base: "full", lg: "60%" }} >
                    <Image src={banner1} alt={'Carousel Image'} objectFit={'cover'} h={"700px"} w={"full"} />
                </Box>
            </Flex>

            <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} bg={"easycar1.500"}>
                <Box order={{ base: 2, lg: 1 }} className={'sideLeft'} color={"white"} textAlign={"center"} w={"full"} maxW={{ base: "full", lg: "40%" }} p={"6px"} pl={"5%"} m={"auto"} >
                    <Heading className={"colorWhite"}>¡El vehículo perfecto para tus aventuras!</Heading>
                    <br />
                    <Text as={"b"} className={"colorWhite"}>Garantía de 90 días</Text>
                    <br /><br />
                    <Link to={"/cars"}>
                        <Button colorScheme={"easycar2"} >Precalifica ahora</Button>
                    </Link>
                </Box>
                <Flex order={{ base: 1, lg: 2 }} className={'sideRight'} bg={"white"} w={"full"} maxW={{ base: "full", lg: "60%" }} justifyContent={"end"} alignItems={"center"} overflow={"hidden"} maxHeight={"700px"}>
                    <Image src={banner2} alt={'Carousel Image'} h={"1000px"} w={"auto"} />
                </Flex>
            </Flex>



            <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} bg={"easycar1.500"}>
                <Box order={{ base: 1, lg: 1 }} className={'sideLeft2'} w={"full"} maxW={{ base: "full", lg: "50%" }}  >
                    <Image src={banner3} alt={'Carousel Image'} objectFit={'cover'} h={"700px"} w={"full"} />
                </Box>
                <Box order={{ base: 2, lg: 2 }} className={'sideRight2'} w={"full"} maxW={{ base: "full", lg: "50%" }} p={"6px"} pl={"5%"} m={"auto"} >
                    <Heading className={"colorWhite"}>¡El vehículo perfecto para tus aventuras!</Heading>
                    <br />
                    <Text as={"b"} className={"colorWhite"}>Garantía de 90 días</Text>
                    <br /><br />
                    <Link to={"/cars"}>
                        <Button colorScheme={"easycar2"} >Compra tu vehículos</Button>
                    </Link>
                </Box>
            </Flex>

        </CarouselMulti>
    )
}