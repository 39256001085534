import React from "react"
import HeroCarousel from "../components/HeroCarousel"
import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SideCar from "../components/SideCar"
import ModalApp from "../containers/Modal";
import { Flex, Box, Heading, Text, Button, Image, Card, CardBody, Icon } from '@chakra-ui/react'
import Layout from '../layout'
import card2 from '../multimedia/slides-cards/1.jpg'
import card3 from '../multimedia/slides-cards/2.jpg'
import card4 from '../multimedia/slides-cards/3.jpg'
import card5 from '../multimedia/slides-cards/4.jpg'
import card6 from '../multimedia/slides-cards/5.jpg'
import agencia1 from '../multimedia/imagenes/palmira.jpg'
import agencia2 from '../multimedia/imagenes/choluteca.png'
import agencia3 from '../multimedia/imagenes/comayagua.jpg'
import agencia4 from '../multimedia/imagenes/sanpedrosula.jpg'
import vehiculo from '../multimedia/imagenes/vehiculo.png'
import call from '../multimedia/imagenes/call2.jpg'
import { Link } from "react-router-dom"
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'


const responsive = {
    largeDesktop: {
        breakpoint: { max: 3000, min: 1400 },
        items: 4,
        partialVisibilityGutter: 30
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 3,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30
    }
};

const CustomRightArrow = ({ onClick, ...rest }) => {
    return <Button onClick={() => onClick()} style={{
        position: "absolute", outline: 0, transition: "all .5s", zIndex: 1000, opacity: 1, cursor: "pointer", border: 0, background: "rgba(0,0,0,0.7)", right: "0", height: "100%"
    }}>
        <Icon as={ChevronRightIcon} color={"white"} boxSize={8} />
    </Button>
}

const CustomLeftArrow = ({ onClick, ...rest }) => {
    return <Button onClick={() => onClick()} style={{
        position: "absolute", outline: 0, transition: "all .5s", zIndex: 1000, opacity: 1, cursor: "pointer", border: 0, background: "rgba(0,0,0,0.7)", left: "0", height: "100%"
    }}>
        <Icon as={ChevronLeftIcon} color={"white"} boxSize={8} />
    </Button>
}

const Landing = () => {

    return (
        <Layout>
            <HeroCarousel />

            <Box maxW={"1400px"} margin={"auto"} mb={"30px"} mt={"7.5rem"} p={5}>
                <Heading textAlign={"center"}>¿Por qué comprar un vehículo con nosotros?</Heading>
                <br /><br />
                <CarouselMulti showDots={false} infinite={false} responsive={responsive} partialVisible={true} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} >

                    <SideCar
                        src={card2}
                        title={"Nuestros vehículos cuentan con una inspección certificada"}
                        content={"Revisamos nuestros vehículos para asegurarnos que todo se encuentre en orden."}
                    />

                    <SideCar
                        src={card3}
                        title={"Contamos con variedad de vehículos"}
                        content={"Conoce nuestras diferentes marcas y modelos de vehículos para que elijas lo que siempre soñaste."}
                    />
                    <SideCar
                        src={card4}
                        title={"Ofrecemos canones accesibles"}
                        content={"Precalificamos en 10 minutos."}
                    />

                    <SideCar
                        src={card5}
                        title={"Entregamos a domicilio"}
                        content={"Te entregamos tu vehículo a la puerta de tu casa o lugar de trabajo. (Consulta condiciones)"}
                    />
                    <SideCar
                        src={card6}
                        title={"Nuestros vehículos como nuevos"}
                        content={"Acondicionamos y preparamos nuestros vehículos en una red de talleres especializados."}
                    />
                </CarouselMulti>
                <br />
                <Box textAlign={"center"}>
                    <Link to={"/cars"} onClick={() => window.scrollTo(0, 0)}>
                        <Button colorScheme={"easycar1"} >Compra tu vehículo</Button>
                    </Link>
                </Box>
            </Box>

            <Box w={"full"} bg={"easycar1.500"} mt={"7.5rem"}>
                <Flex maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                    <Box flex={1} p={"10"} margin={"auto"} color={"white"} maxW={"600px"} >
                        <Heading>Intercambia, compra o vende tu vehículo en 24 horas</Heading>
                        <br />
                        <Text>Acércate a tu agencia más cercana.</Text>
                        <br /><hr /> <br />
                        <Text>Pregunta por nuestra visita a domicilio.</Text>
                        <br /><hr /> <br />
                        <Text>Vende tu vehículo sin tanto trámite y al mejor valúo.</Text>
                        <br /><hr /> <br />
                        <Text>Tomamos tu vehículo a cuenta para que estrenes un EASYCAR.</Text>
                        <br />
                        <Link to={"/vende"} onClick={() => window.scrollTo(0, 0)}>
                            <Button bg={"white"} color={"black"} >Vende tu vehículo</Button>
                        </Link>
                    </Box>
                    <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'Vehiculos easycar'} height={"auto"} width={{ base: '100%', md: "100%" }} src={vehiculo} />
                    </Box>
                </Flex>
            </Box>

            <Box w={"full"} mt={"7.5rem"}>
                <Flex maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                    <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'Call Center'} height={"auto"} width={{ base: '100%', md: "100%" }} src={call} />
                    </Box>
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Heading>Aprobamos tu plan de pago con la mejor asesoría</Heading>
                        <br />
                        <Text>Contamos con asesores personalizados que te acompañaran en todo el proceso</Text>
                        <br />
                        <Link to={"/requisitos"} onClick={() => window.scrollTo(0, 0)} >
                            <Button colorScheme="easycar1">Compra tu vehículo en cuotas</Button>
                        </Link>
                    </Box>
                </Flex>
            </Box>

            <Box bg={'gray.100'} w={"full"} p={5} mt={"7.5rem"}>
                <Heading textAlign={"center"}>¡Visita nuestras sedes y conócenos!</Heading>
                <LoadScript googleMapsApiKey="AIzaSyCggvraT4pGMXL0J4wLlfp_b5yoViJ95EY" >
                    <Flex flexDir={{ base: 'column', lg: 'row' }} flexWrap={"wrap"} justifyContent={"space-around"} alignItems={"center"} p={"6"} mt={"10px"} gap={"10px"}>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Box maxH={"350px"} overflow={"hidden"}>
                                <Image src={agencia1} w={"100%"} objectFit={'cover'} />
                            </Box>
                            <CardBody>
                                <Text as={"b"}>Agencia Palmira</Text>
                                <Text>Colonia Palmira, avenida república de Chile, contiguo a CADERH, Tegucigalpa</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9484-1970</Text>
                                <ModalApp title={"Agencia Palmira"} >

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 14.101008502992348, lng: -87.19782151893402 }}
                                    >
                                        <Marker position={{ lat: 14.101008502992348, lng: -87.19782151893402 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Colonia Palmira, avenida república de Chile, contiguo a CADERH, Tegucigalpa.</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>+504 9484-1970</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>

                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Box maxH={"350px"} overflow={"hidden"}>
                                <Image src={agencia2} h={"350px"} objectFit={'cover'} />
                            </Box>
                            <CardBody>
                                <Text as={"b"}>Agencia Choluteca</Text>
                                <Text>Barrio el hospital, plaza futura, contiguo a farmacia Kielsa</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9581-5675</Text>
                                <ModalApp title={"Agencia Choluteca"} >

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 13.302200825907976, lng: -87.19820827393193 }}
                                    >
                                        <Marker position={{ lat: 13.302200825907976, lng: -87.19820827393193 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Barrio el hospital, plaza futura, contiguo a farmacia Kielsa</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>+504 9581-5675</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>


                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Box maxH={"350px"} overflow={"hidden"}>
                                <Image src={agencia3} h={"350px"} w={"100%"} objectFit={'cover'} />
                            </Box>
                            <CardBody>
                                <Text as={"b"}>Agencia Comayagua</Text>
                                <Text>Barrio Torondon, plaza roble, Comayagua.</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9849-2795</Text>
                                <ModalApp title={"Agencia Comayagua"} >

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 14.4511706, lng: -87.6425045 }}
                                    >
                                        <Marker position={{ lat: 14.4511706, lng: -87.6425045 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Barrio Torondon, plaza roble, Comayagua.</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>+504 9849-2795</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>

                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Box maxH={"350px"} overflow={"hidden"}>
                                <Image src={agencia4}  w={"100%"} objectFit={'cover'} />
                            </Box>
                            <CardBody>
                                <Text as={"b"}>Agencia San Pedro Sula</Text>
                                <Text>Plaza del Carmen, local No. 1, 6 calle, 16 y 17 avenida S.O. Barrio Suyapa, San Pedro Sula, a un costado de la agencia principal de banco Ficohsa.</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 ####-####</Text>
                                <ModalApp title={"Agencia San Pedro Sula"} >

                                    
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Plaza del Carmen, local No. 1, 6 calle, 16 y 17 avenida S.O. Barrio Suyapa, San Pedro Sula, a un costado de la agencia principal de banco Ficohsa.</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>+504 ####-####</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>

                    </Flex>
                </LoadScript>
                <Box textAlign={"center"}>
                    <Link to={"/agencias"} onClick={() => window.scrollTo(0, 0)}>
                        <Button colorScheme="easycar1">Conoce nuestras sedes</Button>
                    </Link>
                </Box>
            </Box>

            <Box textAlign={"center"} w={"full"} mt={"7.5rem"} mb={"2rem"}>
                <Heading>Lo que opinan nuestros clientes</Heading>
            </Box>
            <Box display={"flex"} flexDir={"column"} alignItems={"center"} justifyContent={"center"} w={"full"} textAlign={"center"} mb={"2rem"} >
                <Flex w={"full"} flexDir={{ base: 'column', lg: 'row' }} justifyContent={"space-around"} alignItems={"center"} gap={"10px"}>
                    <iframe
                        className={"video"}
                        src="https://www.youtube.com/embed/C43o-mQwiNM"
                        title="Experiencia Easycar 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                        allowFullScreen></iframe>
                    <iframe
                        className={"video"}
                        src="https://www.youtube.com/embed/x88-Zx_nT9c"
                        title="Experiencia Easycar 2"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                        allowFullScreen></iframe>
                </Flex>
                <Link to={"/experiencia-easycar"} onClick={() => window.scrollTo(0, 0)}>
                    <Button colorScheme="easycar1" mt={"1rem"}>Ver más historias de clientes</Button>
                </Link>
            </Box>
        </Layout>
    )
}

export default Landing