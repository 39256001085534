import { Card, CardBody, Image, Text, Flex, Box } from '@chakra-ui/react'
import Layout from '../layout'
import agencia1 from '../multimedia/imagenes/palmira.jpg'
import agencia2 from '../multimedia/imagenes/choluteca.png'
import agencia3 from '../multimedia/imagenes/comayagua.jpg'
import agencia4 from '../multimedia/imagenes/sanpedrosula.jpg'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import ModalApp from "../containers/Modal"

const Agencias = () => {

    return (
        <Layout>
            <LoadScript googleMapsApiKey="AIzaSyCggvraT4pGMXL0J4wLlfp_b5yoViJ95EY" >
                <Flex flexDir={{ base: 'column', lg: 'row' }} flexWrap={"wrap"} justifyContent={"space-around"} alignItems={"center"} p={"6"} mt={"10px"} gap={"10px"}>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Box maxH={"350px"} overflow={"hidden"}>
                            <Image src={agencia1} w={"100%"} objectFit={'cover'} />
                        </Box>
                        <CardBody>
                            <Text as={"b"}>Agencia Palmira</Text>
                            <Text>Colonia Palmira, avenida república de Chile, contiguo a CADERH, Tegucigalpa</Text>
                            <Text as={"b"}>Télefonos:</Text>
                            <Text>+504 9484-1970</Text>
                            <ModalApp title={"Agencia Palmira"} >

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 14.101008502992348, lng: -87.19782151893402 }}
                                >
                                    <Marker position={{ lat: 14.101008502992348, lng: -87.19782151893402 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Colonia Palmira, avenida república de Chile, contiguo a CADERH, Tegucigalpa.</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9484-1970</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Box maxH={"350px"} overflow={"hidden"}>
                            <Image src={agencia2} h={"350px"} objectFit={'cover'} />
                        </Box>
                        <CardBody>
                            <Text as={"b"}>Agencia Choluteca</Text>
                            <Text>Barrio el hospital, plaza futura, contiguo a farmacia Kielsa</Text>
                            <Text as={"b"}>Télefonos:</Text>
                            <Text>+504 9581-5675</Text>
                            <ModalApp title={"Agencia Choluteca"} >

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 13.302200825907976, lng: -87.19820827393193 }}
                                >
                                    <Marker position={{ lat: 13.302200825907976, lng: -87.19820827393193 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Barrio el hospital, plaza futura, contiguo a farmacia Kielsa</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9581-5675</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>


                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Box maxH={"350px"} overflow={"hidden"}>
                            <Image src={agencia3} h={"350px"} w={"100%"} objectFit={'cover'} />
                        </Box>
                        <CardBody>
                            <Text as={"b"}>Agencia Comayagua</Text>
                            <Text>Barrio Torondon, plaza roble, Comayagua.</Text>
                            <Text as={"b"}>Télefonos:</Text>
                            <Text>+504 9849-2795</Text>
                            <ModalApp title={"Agencia Comayagua"} >

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 14.4511706, lng: -87.6425045 }}
                                >
                                    <Marker position={{ lat: 14.4511706, lng: -87.6425045 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Barrio Torondon, plaza roble, Comayagua.</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 9849-2795</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Box maxH={"350px"} overflow={"hidden"}>
                            <Image src={agencia4} w={"100%"} objectFit={'cover'} />
                        </Box>
                        <CardBody>
                            <Text as={"b"}>Agencia San Pedro Sula</Text>
                            <Text>Plaza del Carmen, local No. 1, 6 calle, 16 y 17 avenida S.O. Barrio Suyapa, San Pedro Sula, a un costado de la agencia principal de banco Ficohsa.</Text>
                            <Text as={"b"}>Télefonos:</Text>
                            <Text>+504 ####-####</Text>
                            <ModalApp title={"Agencia San Pedro Sula"} >


                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Plaza del Carmen, local No. 1, 6 calle, 16 y 17 avenida S.O. Barrio Suyapa, San Pedro Sula, a un costado de la agencia principal de banco Ficohsa.</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>+504 ####-####</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>

                </Flex>
            </LoadScript>
        </Layout>
    )

}

export default Agencias