import React from 'react'
import Layout from '../layout'
import image1 from '../multimedia/imagenes/gps.jpg'
import image2 from '../multimedia/imagenes/pago2.png'
import image3 from '../multimedia/logo-expandido.png'
import image4 from '../multimedia/imagenes/tigo.png'
import image5 from '../multimedia/imagenes/pago3.png'
import { Flex, Box, Card, Heading, Text, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

class PuntoPago extends React.Component {

    render() {

        return (
            <Layout>

                <Box w={"full"} >
                    <Flex maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"} mt={"3.5rem"} mb={"2rem"}>
                        <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                            <Image alt={'Gps'} height={"auto"} width={'100%'} src={image1} />
                        </Box>
                        <Box flex={1} order={{ base: 2, md: 1 }} p={"10"} margin={"auto"} >
                            <Heading>¡Hacemos más fácil tu forma de pago!</Heading>
                            <br />
                            <Text> Puedes realizar tus pagos en los siguientes puntos</Text>
                        </Box>
                    </Flex>
                </Box>

                <Box w={"full"} bg={'gray.100'}>
                    <Flex textAlign={"center"} maxW={"1400px"} flexDir={'column'} p={4} m={"auto"} mt={"3.5rem"}>
                        <Heading>Canales de pago pensados para ti</Heading>
                        <Flex alignItems={"center"} justifyContent={"space-evenly"} p={"6"} minH={"500px"} gap={3}>
                            <a target="_blank" rel="noreferrer" href={"https://www.tigo.com.hn/tigo-money/app"}>
                                <Card w={"350px"} height={"210px"} display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={3} className='miniCarIcon'>
                                    <Image src={image4} maxH={"200px"} />
                                </Card>
                            </a>
                            <Link to={"agencias"}>
                                <Card w={"350px"} height={"210px"} display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={4} className='miniCarIcon'>
                                    <Image src={image3} width={"full"} h={"auto"} />
                                </Card>
                            </Link>


                            <Card w={"350px"} height={"210px"} display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={3} className='miniCarIcon'>
                                <Image src={image2} maxH={"200px"} />
                            </Card>


                            <Card w={"350px"} height={"210px"} display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={3} className='miniCarIcon'>
                                <Image src={image5} maxH={"200px"} />
                            </Card>
                        </Flex>
                    </Flex>
                </Box>
            </Layout>
        )
    }

}

export default PuntoPago