import { Card, CardBody, Image, Text, Heading } from '@chakra-ui/react'

const SideCar = (props) => {

    const { src, title, content } = props
    return (
        <Card direction={"column"} alignItems={"center"} width={"100%"} maxW={"300px"} height={"100%"} maxHeight={"525px"}>
            <Image src={src} h={"300px"} w={"full"} objectFit={'cover'} />
            <CardBody>
                <Heading as={'h5'} size='md'>{title} </Heading>
                <br/>
                <Text>{content}</Text>
            </CardBody>
        </Card>
    )
}

export default SideCar